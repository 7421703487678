import {Injectable} from '@angular/core';
import {createAction, props, Store} from '@ngrx/store';
import {Indicators} from '../../../models/indicators';


export class IndicatorsActionConstant {
  static readonly LOAD_COUNTERS_START = 'LOAD_COUNTERS_START';
  static readonly LOAD_COUNTERS_SUCCEEDED = 'LOAD_COUNTERS_SUCCEEDED';
  static readonly LOAD_COUNTERS_FAILED = 'LOAD_COUNTERS_FAILED';
}
export const loadCountersStartAction = createAction(IndicatorsActionConstant.LOAD_COUNTERS_START);

export const loadCountersSucceededAction = createAction(IndicatorsActionConstant.LOAD_COUNTERS_SUCCEEDED, props<{ payload: Indicators }>());

export const loadCountersFailedAction = createAction(IndicatorsActionConstant.LOAD_COUNTERS_FAILED, props<{ error: Error }>());

@Injectable({
  providedIn: 'root'
})
export class IndicatorsAction {

  constructor(
    private localStore: Store
  ) {
  }

  public loadStarted(): void {
    this.localStore.dispatch(loadCountersStartAction());
  }

  public loadSucceeded(payload: Indicators): void {
    this.localStore.dispatch(loadCountersSucceededAction({payload}));
  }

  public loadFailed(error: any) {
    this.localStore.dispatch(loadCountersFailedAction({error}));
  }
}
