import { Lock } from './lock';
import { EsAuthor } from './EsAuthor';

export enum MetadataCodes {
  ARCHIVE_LOCK = 'archive_lock',
  ALERTS = 'alerts'
}

export class MetadataAlert {
  key?:string;
  delay: string;
  comment: string;
  type_alerte: {
    code: string;
    i18n: {
      fr :string;
      en ?:string
    };
  };
  cdx_creation_date: string;
  cdx_activation_date: string;
  cdx_last_check_date: string;
  cdx_id: string;
  is_active?: boolean;
  cdx_author?:EsAuthor
}

export class Metadata {
  archive_lock?: Lock;
  alerts?: {[key: string]: MetadataAlert};
}
