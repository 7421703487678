

export enum AccessFunction {
  INDEX = 'INDEX',
  INDEXENTITY = 'INDEXENTITY',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  HARDDELETE = 'HARDDELETE',
  READCOMMENT = 'READCOMMENT',
  CREATECOMMENT = 'CREATECOMMENT',
  DELETECOMMENT = 'DELETECOMMENT',
  UPDATECOMMENT = 'UPDATECOMMENT',
  READATTACHMENT = 'READATTACHMENT',
  CREATEATTACHMENT = 'CREATEATTACHMENT',
  DELETEATTACHMENT = 'DELETEATTACHMENT',
  UPDATEATTACHMENT = 'UPDATEATTACHMENT',
  READHISTORY = 'READHISTORY',
  ADMINCOMMENT = 'ADMINCOMMENT',
  ADMINATTACHMENT = 'ADMINATTACHMENT',
  INDEX_TEAM = 'INDEX_TEAM',
  INDEX_TEAM_ALL = 'INDEX_TEAM_ALL',
  INDEX_TEAM_ADMIN = 'INDEX_TEAM_ADMIN',
  TEAM = 'TEAM',
  TEAM_ALL = 'TEAM_ALL',
  TEAM_ADMIN = 'TEAM_ADMIN',
  READCRITICAL = 'READCRITICAL'
}

export enum NexiaAuthorities {
  INDEX_TEAM = 'INDEX_TEAM',
  INDEX_TEAM_ALL = 'INDEX_TEAM_ALL',
  INDEX_TEAM_ADMIN = 'INDEX_TEAM_ADMIN',
  TEAM = 'TEAM',
  TEAM_ALL = 'TEAM_ALL',
  TEAM_ADMIN = 'TEAM_ADMIN',
  SUPERUSER = 'SUPERUSER',
  DOMAIN_SUPERUSER = 'DOMAIN_SUPERUSER'
}


export function findAuthority(authorityList: { [key: string]: string[] }, checkedAuthority: NexiaAuthorities, domainCode) {
  for (const authority in authorityList) {
    if (checkedAuthority === authority) {
      const domains = authorityList[authority];
      if (domains.length === 0) {
        return true;
      }
      if (domains.length > 0 && !!domainCode && domains.indexOf(domainCode) > -1) {
        return true
      }
    }
  }
  return false;
}

export class Access {
  domain: string;
  region: string;
  family: string;
  docTypes: string[];
}
