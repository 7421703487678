import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CountersComponent} from './counters/counters.component';
import {CounterComponent} from './counter/counter.component';
import {TranslateModule} from "@ngx-translate/core";
import { TooltipModule } from '../tooltip/tooltip.module';


@NgModule({
  declarations: [

    CountersComponent,
    CounterComponent
  ],
  exports: [
    CountersComponent
  ],
    imports: [
        CommonModule,
        TranslateModule,
        TooltipModule
    ]
})
export class IndicatorsModule {
}
