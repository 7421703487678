export enum InternalRoutes {
  DETAILS = 'details',
  COMMENTS = 'comments',
  ATTACHMENTS = 'attachments',
  HISTORY = 'history',
  EDITION = 'edition',
  LAST_ADDED_DOCUMENTS = 'last-added-documents',
  DEBUG = 'debug',
  NEW = 'new',
  FILE = 'file',
  RESULT = 'result',
  ERROR = 'error',
  LOGIN = 'login',
  SEARCH = 'search',
  ENTITIES = 'entities',
  INDEXATION = 'indexation',
  DOMAIN = 'domain',
  DASHBOARD = 'dashboard',
  DOCUMENTS = 'documents',
  DOCUMENT = 'document',
  MULTIPLE = 'multiple',
  TEAMS = 'teams',
  RECAP = 'recap',
  SUPPORT = 'support',
  INFOS = 'information',
  ALERTS = 'alerts',
  USERS = 'users',
  WORKFLOW = 'workflow',
  TASKS = 'tasks',
  BASKET = 'basket',
  URL_ONE = 'One',
  URL_TWO = 'Two',
  URL_THREE = 'Three',
  URL_FOUR = 'Four',
  URL_FIVE = 'Five',
  URL_SIX = 'Six',
  URL_SEVEN = 'Seven',
  URL_EIGHT = 'Eight',
  URL_NINE = 'Nine',
  TECHBI = 'ext/techbi',
}
