import { FromJson } from './fromJson';
import { ObjectStatus } from './object-status';

export enum MenuItemRightsType {
  NATIVE = 'NATIVE',
  ROLE = 'ROLE'
}

export enum MenuItemActionsType {
  NATIVE = 'NATIVE',
  INTERNAL_LINK_NEW_TAB = 'INTERNAL_LINK_NEW_TAB',
  INTERNAL_LINK = 'INTERNAL_LINK',
  LINK_NEW_TAB = 'LINK_NEW_TAB',
  LINK = 'LINK'
}

export enum DeniedBehaviour {
  DISABLE = 'DISABLE',
  HIDE = 'HIDE'
}

export class MenuItemRights {
  type: MenuItemRightsType;
  deniedBehaviour: DeniedBehaviour;
  role: string;
  domains: string[];
}

export class MenuItemAction {
  icon: string;
  type: MenuItemActionsType;
  action: string;
  code: string;
}

export class MenuItem extends FromJson {
  id: number;
  rights: MenuItemRights;
  action: MenuItemAction;
  sort: number;
  status: ObjectStatus;
  label: string;
  active = true;

  public static getRouterPathByAction(actionMenuItem: MenuItemAction): string {
    switch (actionMenuItem.action) {
      case 'SEARCH':
        return '/search';
      case 'TEAMS':
        return '/domain';
      case 'NEW_ENTITIES':
        return '/entities/';
      case 'INDEXATION':
        return '/indexation';
      case 'PARAMETERS':
        return '/admin';
      case 'SUPPORT':
        return '/support';
      case 'NEXIABI' :
        return '/ext/techbi';
      default:
        return actionMenuItem.action;
    }
  }
}
