import {Indicators} from '../../../models/indicators';
import {createReducer, on} from '@ngrx/store';
import {
  loadCountersFailedAction,
  loadCountersStartAction,
  loadCountersSucceededAction
} from '../action/indicators.action';


export interface IndicatorsState {
  datas: Indicators;
  loading: boolean;
  error: any;
}

export const INDICATORS_INITIAL_STATE: IndicatorsState = {
  datas: {
    counters: []
  },
  loading: false,
  error: null,
};


export const indicatorsReducer = createReducer(INDICATORS_INITIAL_STATE,
  on(loadCountersStartAction, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(loadCountersSucceededAction, (state, action) => ({
    ...state,
    datas: {
      ...state.datas,
      ...action.payload
    },
    loading: false,
    error: null,
  })),
  on(loadCountersFailedAction, (state, action) => ({
    ...state,
    datas: {
      counters: []
    },
    loading: false,
    error: action.error,
  })),
)
