import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { EntityCardComponent } from './components/card/entity-card/entity-card.component';
import { EntityCardActionsComponent } from './components/card/entity-card-actions/entity-card-actions.component';
import { EntityBadgeComponent } from './components/entity-badge/entity-badge.component';
import { EntitiesPopupComponent } from './components/entities-popup/entities-popup.component';
import {MatIconModule} from '@angular/material/icon';
import {NxI18nPipeModule} from '../nxI18nPipe/nxI18nPipe.module';
import {TooltipModule} from '../tooltip/tooltip.module';
import {IndicatorsModule} from "../../ui/indicators/indicators.module";

@NgModule({
  declarations: [
    EntityCardComponent,
    EntityCardActionsComponent,
    EntityBadgeComponent,
    EntitiesPopupComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild( [] ),
    TranslateModule,
    MatIconModule,
    NxI18nPipeModule,
    TooltipModule,
    IndicatorsModule
  ],
  exports: [
    EntityCardComponent,
    EntityCardActionsComponent,
    EntityBadgeComponent,
    EntitiesPopupComponent
  ]
})
export class EntityModule { }
