import { CdxData } from './cdx-data';
import { CdxClassification } from './cdx-classification';
import { EsAuthor } from './EsAuthor';
import {Metadata} from './metadata';

export class Entity {
  cdx_id: string;
  cdx_author: EsAuthor;
  cdx_creation_date: Date;
  cdx_modification_author: EsAuthor;
  cdx_modification_date: Date;
  cdx_date_eol: Date;
  cdx_title: string;
  cdx_subtitle: string;
  cdx_description: string;
  cdx_datas: {[key: string]: CdxData};
  cdx_links: {[key: string]: Entity[]};
  cdx_classification: CdxClassification;
  cdx_version: number;
  cdx_status: string;
  token: string;
  external_id: string;
  metadata: Metadata;
}
