export enum StoreKeys {
  APPLICATION = 'application',
  AUTHENTICATION = 'authentication',
  GED_CFG_FIELD = 'gedCfgField',
  WKF_CFG_FIELD = 'wkfCfgField',
  CFG_TEAM = 'cfgTeam',
  MENU_ITEMS = 'menu_items',
  CURRENT_CONTEXT = 'currentContext',
  SEARCH_ITEMS = 'searchItems',
  TASK_SEARCH_ITEMS = 'taskSearchItems',
  FILTERS = 'filters',
  TASK_FILTERS = 'taskFilters',
  DOCUMENT_SEARCH_RESULT = 'document_search_result',
  TASK_SEARCH_RESULT = 'taskSearchResult',
  ENTITY_SEARCH_ITEMS = 'entitySearchItems',
  ENTITY_FILTERS = 'entityFilters',
  ENTITY_SEARCH_RESULT = 'entitySearchResult',
  DOMAINS = 'domains',
  CFG_DOCUMENT_INFO = 'cfgDocumentInfo',
  VIEW = 'view',
  TASK_FORM = 'taskForm',
  LIST_INDEXATION = 'listIndexation',
  DRAFT_FILTERS = 'draftFilters',
  DRAFT_DATAS = 'draftDatas',
  INDEXATION_DOC_TYPES = 'indexationDocTypes',
  CACHE_DRAFT = 'cacheDraft',
  DOCUMENT_DETAILS = 'documentDetails',
  TASK_DETAILS = 'taskDetails',
  CURRENT_COMMENT = 'currentComment',
  ROUTER_PATH = 'routerPath',
  ENTITY_VIEW = 'entityView',
  INDEXATION_VIEW = 'indexationView',
  ENTITY_DETAILS = 'entityDetails',
  CACHE_FORM = 'cacheForm',
  TEAMS_PAGE = 'teamsPage',
  CURRENT_TEAM = 'currentTeam',
  CURRENT_TEAM_USERS_PAGE = 'currentTeamUsersPage',
  MAINTENANCE_INFO = 'maintenanceInfo',
  CRITICAL_DATA = 'criticalData',
  VERSION_LIST = 'versionList',
  TABLE_FILTER = 'tableFilter',
  INDEXATION_TEAMS = 'indexationTeams',
  DOMAIN_TEAMS = 'domainTeams',
  USERS = 'users',
  NEXIA_OBJECT_LINKS = 'nexiaObjectLinks',
  SEARCH_RESULT_PAGINATION = 'search_result_pagination',
  NEXIA_BASKET = 'nexiaBasket',
  INDICATORS = 'indicators'
}
