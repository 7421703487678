import {createFeature, StoreModule} from '@ngrx/store';
import {StoreKeys} from '../../models/store-keys';
import {indicatorsReducer} from './reducer/indicators.reducer';


export const indicatorsStoreConfig = StoreModule.forFeature(
  createFeature({
    name: StoreKeys.INDICATORS,
    reducer:  indicatorsReducer
  })
);
