import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, OperatorFunction, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ConfigurationService } from '../../../../modules/configuration/configuration.service';
import { LoadableThesaurus, LoadableThesaurusItem } from '../../../models/loadable-thesaurus';
import { Url } from '../../../models/url';
import { Page } from '../../../models/page';
import { RegionItemI18n } from '../../../models/region-item-i18n';
import { Status } from '../../../models/status';

@Injectable()
export class LoadableThesaurusService {

  public static readonly THESAURUS_PAGE_SIZE = 5;
  public static readonly THESAURUS_MAX_SIZE = 50;
  currentLanguage: string;

  constructor(
    private httpClient: HttpClient,
    private configAction: ConfigurationService,
    private translateService: TranslateService
  ) {
    this.currentLanguage = this.translateService.currentLang;
  }

  private query(): Observable<HttpParams> {
    const httpParams = new HttpParams();
    return of(httpParams);
  }

  private getServiceBaseUrl(thesaurusCode: string) {
    return Url.getProtectedApiBaseUrl(this.configAction) + Url.THESAURUS + thesaurusCode + '/';
  }

  public getThesaurusSubTree(thesaurusCode: string, thesaurusEntrypointCode: string): Promise<LoadableThesaurus> {
    return new Promise<LoadableThesaurus>((resolve, reject) => {
      this.rest_getEntrypoint(thesaurusCode, thesaurusEntrypointCode).then((resp) => {
        const subTree =
          {
            code: thesaurusCode,
            label: '-NOT LOADED-',
            status: 'ACTIVE',
            items: {}
          } as LoadableThesaurus;
        const entrypointItem: LoadableThesaurusItem = resp;

        this.getItem(subTree, entrypointItem.value).then((response: LoadableThesaurusItem) => {
          if (response.hasChildren) {
            entrypointItem.hasChildren = response.hasChildren;
          }
          subTree.items[entrypointItem.value] = entrypointItem;
          resolve(subTree);
        });

      }).catch((err) => {
        reject(err);
      });
    });
  }

  public getThesaurusRootName(root: LoadableThesaurus): string {
    if (root.items != null && Object.keys(root.items).length > 0) {
      return Object.keys(root.items)[0];
    }
    return null;
  }

  public getItem(root: LoadableThesaurus, itemValue: string, page = 0, size: number = LoadableThesaurusService.THESAURUS_PAGE_SIZE): Promise<LoadableThesaurusItem> {
    return this.rest_getChildren(root, {value: itemValue} as LoadableThesaurusItem, page, size);
  }

  public getEntrypointPath(thesaurusCode: string, thesaurusEntrypointCode: string): Observable<any> {
    return this.query().pipe(
      this.getEntrypointByThesaurusCodeAndEntrypointCode(thesaurusCode, thesaurusEntrypointCode)
    );
  }

  // // FIXME : manage pageOffset
  private rest_getChildren(root: LoadableThesaurus, item: LoadableThesaurusItem, page: number, size): Promise<LoadableThesaurusItem> {
    // const url = this.getServiceBaseUrl(root) + '/' + escape(item.value) + '/children';
    const url = this.getServiceBaseUrl(root.code) + Url.ITEMS + escape(item.value) + '/' + Url.CHILDREN;
    console.log('GET ' + url);

    // BEWARE : will return a page of PseudoItems
    return new Promise<LoadableThesaurusItem>((resolve, reject) => {
      this.query().pipe(
        this.page(page, size),
        this.requestChildsPage(url),
        map((response) => {
          const children = {};
          let cpt = 0;
          if (response.content != null) {
            for (const child of response.content) {
              // FIXME remove control once the bug on API is fixed (duplicate entries)
              const childrenKeys = Object.keys(children);
              if (childrenKeys.length && childrenKeys.find((childKey: string) => child.value === childKey)) {
                console.warn('TODO: remove control');
                continue;
              }

              if (child.status === Status.DELETED) {
                continue;
              }

              const castedChild = {
                value: child.value,
                label: child.i18n[this.currentLanguage],
                status: child.status,
                hasChildren: child.hasChildren
              } as LoadableThesaurusItem;
              castedChild.parent = item;

              // castedChild.sort is just a transport info for me
              castedChild.localSort = cpt; // will be the arrayIndex Value

              children[castedChild.value] = castedChild;
              cpt++;
            }
          }

          item.hasMoreChildrenThanVisible = !response.last; // FIXME ..?
          item.hasChildren = Object.keys(children).length > 0;
          item.items = children;

          console.log('item is now', item);

          resolve(item);
        }),
        catchError((error) => throwError(reject(error)))
      ).toPromise();
      // .then((response) => {
      //
      //   const children = {};
      //   let cpt = 0;
      //   if (response.content != null) {
      //     for (const child of response.content) {
      //       // FIXME remove control once the bug on API is fixed (duplicate entries)
      //       const childrenKeys = Object.keys(children);
      //       if (childrenKeys.length && childrenKeys.find((childKey: string) => child.value === childKey)) {
      //         console.warn('TODO: remove control');
      //         continue;
      //       }
      //
      //       if (child.status === Status.DELETED) {
      //         continue;
      //       }
      //
      //       const castedChild = {
      //         value: child.value,
      //         label: child.i18n[this.currentLanguage],
      //         status: child.status,
      //         hasChildren: child.hasChildren
      //       } as LoadableThesaurusItem;
      //       castedChild.parent = item;
      //
      //       // castedChild.sort is just a transport info for me
      //       castedChild.localSort = cpt; // will be the arrayIndex Value
      //
      //       children[castedChild.value] = castedChild;
      //       cpt++;
      //     }
      //   }
      //
      //   item.hasMoreChildrenThanVisible = !response.last; // FIXME ..?
      //   item.hasChildren = Object.keys(children).length > 0;
      //   item.items = children;
      //
      //   console.log('item is now', item);
      //
      //   resolve(item);
      // }).catch((err) => {
      //   reject(err);
      // });
    });
  }

  private rest_getEntrypoint(thesaurusCode: string, thesaurusEntrypointCode: string): Promise<LoadableThesaurusItem> {
    return this.query()
      .pipe(
        this.getEntrypointByThesaurusCodeAndEntrypointCode(thesaurusCode, thesaurusEntrypointCode),
        map((response) => {
          const entrypointItem: RegionItemI18n = response.path[response.path.length - 1];
          return {
            value: entrypointItem.value,
            label: entrypointItem.i18n[this.currentLanguage],
            status: entrypointItem.status
          } as LoadableThesaurusItem;
        })
      ).toPromise();
  }

  private requestChildsPage(url: string): OperatorFunction<HttpParams, Page<RegionItemI18n>> {
    return switchMap(params => {
      return this.httpClient.get<Page<RegionItemI18n>>(url, { params }).toPromise();
    });
  }

  private getEntrypointByThesaurusCodeAndEntrypointCode(thesaurusCode: string, thesaurusEntrypointCode: string): OperatorFunction<HttpParams, any> {
    return switchMap(params => {
      return this.httpClient.get<any>(this.getServiceBaseUrl(thesaurusCode) + 'entrypoints/' + escape(thesaurusEntrypointCode), {params: params});
    });
  }

  private page(pageNumber = 0, size: number = LoadableThesaurusService.THESAURUS_PAGE_SIZE): OperatorFunction<HttpParams, HttpParams> {
    return map((params) => {
      params = params
        .set('size', '' + Math.min(size, LoadableThesaurusService.THESAURUS_MAX_SIZE))
        .set('page', '' + pageNumber)
        .set('onlyActivated',true)
      ;

      return params;
    });
  }
}
