// imported in each class
import { TranslateService } from '@ngx-translate/core';
import { FieldType, SearchOperator } from './field';
import { EsAuthor } from './EsAuthor';
import { SearchPath } from './search-path';
import { NxI18nService } from '../ui/nxI18nPipe/nxI18n.service';

export enum OperatorType {
  MATCH = 'MATCH',
  TERM = 'TERM',
  RANGE = 'RANGE',
  EXISTS = 'EXISTS'
}

export interface SearchItemLabelByType {
  searchItem: SearchItem;
  userList: EsAuthor[];
  currentLanguage: string;
}

export class SearchItem {

  public count?: number;

  constructor(
    public id?: number,
    public keyword?: string,
    public path?: string,
    public value?: string,
    public canDelete: boolean = true,
    public fieldType: FieldType = null,
    public operator?: SearchOperator,
    public label?: string,
    public oldValue: string = null
  ) {
  }

  // public authorizeSearchByDirectInput?: boolean;
  // public callAutocompletionOnDirectInput?: boolean;
  public isMetaDataAlert() : boolean {
    let isMetaAlert = false;
    if(  !!this.path) {
      isMetaAlert=this.path.includes(SearchPath.META_DATA_PREFIX + '.' + SearchPath.META_ALERTS);
    }
    return isMetaAlert;
  }

  public static readonly OPERATOR_SEPARATOR = ':';

  public static getOperatorType(operator: SearchOperator): OperatorType {
    let type: OperatorType;
    switch (operator) {
      case SearchOperator.gt:
      case SearchOperator.gte:
      case SearchOperator.lt:
      case SearchOperator.lte:
        type = OperatorType.RANGE;
        break;
      case SearchOperator.c:
        type = OperatorType.MATCH;
        break;
      case SearchOperator.e:
      case SearchOperator.ne:
        type = OperatorType.TERM;
        break;
      case SearchOperator.n:
      case SearchOperator.nn:
        type = OperatorType.EXISTS;
        break;
    }
    return type;
  }

  // public setSearchBehavior() {
  //   switch (this.fieldType) {
  //     case FieldType.AUTHOR:
  //       this.authorizeSearchByDirectInput = false;
  //       this.callAutocompletionOnDirectInput = true;
  //       break;
  //     case FieldType.DATE:
  //       this.authorizeSearchByDirectInput = false;
  //       this.callAutocompletionOnDirectInput = false;
  //       break;
  //     case FieldType.DECIMAL:
  //     case FieldType.INTEGER:
  //       this.authorizeSearchByDirectInput = true;
  //       this.callAutocompletionOnDirectInput = false;
  //       break;
  //     default:
  //       this.authorizeSearchByDirectInput = true;
  //       this.callAutocompletionOnDirectInput = true;
  //       break;
  //   }
  // }

  public static isCallAutocompletionOnDirectInput(searchItem: SearchItem): boolean {
    return [FieldType.DECIMAL, FieldType.INTEGER, FieldType.DATE].indexOf(searchItem?.fieldType) === -1;
  }
  public static isAuthorizeSearchByDirectInput(searchItem: SearchItem): boolean {
    return [FieldType.AUTHOR, FieldType.DATE].indexOf(searchItem?.fieldType) === -1;
  }

  public static getSearchItemLabel(searchItemLabelByType: SearchItemLabelByType, translateService: TranslateService, nxI18nService: NxI18nService): string {
    if (!searchItemLabelByType.searchItem) {
      return '';
    }

    // pas besoin de vérifier si searchpath.ismeta , à partir du moment ou on a un label on le prend
    if ( !!searchItemLabelByType.searchItem.label) {
      return searchItemLabelByType.searchItem.label;
    }

    if (!searchItemLabelByType.searchItem.fieldType) {
      if ( Object.assign(new SearchItem(),searchItemLabelByType.searchItem).isMetaDataAlert()) {
        const alertType =  SearchItem.getItemMetaDataAlertType(searchItemLabelByType.searchItem);
        if(!!alertType) {
          const trad =  nxI18nService.instant('alert_type',alertType,['singular']);
           // si la traduction est trouvée on la renvoie , sinon on renvoie le code de l'alerte
          return trad.indexOf( 'alert_type.' ) == -1 ? trad : alertType
        }
      }

      return searchItemLabelByType.searchItem.value;
    }
    switch (searchItemLabelByType.searchItem.fieldType) {
      case FieldType.AUTHOR:
        return SearchItem.getUserFirstNameLastName(searchItemLabelByType.searchItem, searchItemLabelByType.userList);
      case FieldType.DATE:
        return SearchItem.getLocaleDate(searchItemLabelByType.searchItem, searchItemLabelByType.currentLanguage);
      case FieldType.BOOLEAN:
        return translateService.instant('SEARCH.'+searchItemLabelByType.searchItem.value);
      case FieldType.STRING: {
        if ('metadata.archive_lock.lock_information.lock_type' === searchItemLabelByType.searchItem.path) {
          return translateService.instant('SEARCH.'+searchItemLabelByType.searchItem.value);
        } else {
          return searchItemLabelByType.searchItem.value;
        }
      }
      default:
        return searchItemLabelByType.searchItem.value;
    }
  }

  private static getUserFirstNameLastName(searchItem: SearchItem, userList: EsAuthor[]): string {
    const searchedUser: EsAuthor = userList.find((user: EsAuthor) => user.id === +searchItem.value);
    return searchedUser ? EsAuthor.getUserLabel(searchedUser) : searchItem.value;
  }

  private static getLocaleDate(searchItem: SearchItem, currentLanguage: string): string {
    return new Date(searchItem.value).toLocaleDateString(currentLanguage);
  }

  public static authorizeInputText(searchItem: SearchItem): boolean {
    switch (searchItem.fieldType) {
      case FieldType.BOOLEAN:
        return false;
      default:
        return true;
    }
  }

  public static getItemMetaDataAlertType( searchItem: SearchItem ) {
    let alertType = searchItem.path;
    alertType = alertType.replace(SearchPath.META_DATA_PREFIX + '.' + SearchPath.META_ALERTS+ '.','');
    alertType = alertType.replace('.'+'cdx_activation_date','');
    return alertType;
  }
}
