import {HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {createAction, props, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {ToastaService} from 'ngx-toasta';
import {ActivityDiff, CdxActivity} from '../../../../models/cdx-activity';
import {CdxAttachment} from '../../../../models/cdx-attachment';
import {CdxComment} from '../../../../models/cdx-comment';
import {CdxDocument} from '../../../../models/cdx-document';
import {Entity} from '../../../../models/Entity';
import {EntityDetails} from '../../../../models/entity-details';

import {EsPage} from '../../../../models/es-page';
import {Utils} from '../../../../utils/utils';
import {AbstractDetailsAction} from '../abstract-details.action';
import {EntityDetailsConstant} from '../entity-details-constants';
import {Metadata} from '../../../../models/metadata';


export const loadEntityDetailsStartAction = createAction(EntityDetailsConstant.LOAD_ENTITY_DETAILS_START);
export const loadEntityDetailsSucceededAction = createAction(EntityDetailsConstant.LOAD_ENTITY_DETAILS_SUCCEEDED, props<{
  payload: EntityDetails
}>());
export const loadEntityDetailsFailedAction = createAction(EntityDetailsConstant.LOAD_ENTITY_DETAILS_FAILED, props<{
  error: Error
}>())

export const loadDetailsStartAction = createAction(
  EntityDetailsConstant.LOAD_DETAILS_START
);

export const loadNexiaObjectDetailsStartAction = createAction(
  EntityDetailsConstant.LOAD_ENTITY_DETAILS_START
);

export const loadCurrentActivityDiffStartAction = createAction(
  EntityDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_START
);

export const loadDetailsSucceededAction = createAction(
  EntityDetailsConstant.LOAD_DETAILS_SUCCEEDED,
  props<{ payload: EntityDetails }>()
);

export const loadNexiaObjectDetailsSucceededAction = createAction(
  EntityDetailsConstant.LOAD_ENTITY_DETAILS_SUCCEEDED,
  props<{ payload: EntityDetails }>()
);

export const loadCurrentActivityDiffSucceededAction = createAction(
  EntityDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_SUCCEEDED,
  props<{ payload: EntityDetails }>()
);

export const loadDetailsFailedAction = createAction(
  EntityDetailsConstant.LOAD_DETAILS_FAILED,
  props<{ error: any }>()
);

export const loadNexiaObjectDetailsFailedAction = createAction(
  EntityDetailsConstant.LOAD_ENTITY_DETAILS_FAILED,
  props<{ error: any }>()
);

export const loadCurrentActivityDiffFailedAction = createAction(
  EntityDetailsConstant.LOAD_CURRENT_ACTIVITY_DIFF_FAILED,
  props<{ error: any }>()
);

export const addCommentStartAction = createAction(
  EntityDetailsConstant.ADD_COMMENT_START
);

export const addCommentFailedAction = createAction(
  EntityDetailsConstant.ADD_COMMENT_FAILED,
  props<{ error: any }>()
);

export const updateCommentStartAction = createAction(
  EntityDetailsConstant.UPDATE_COMMENT_START
);

export const updateCommentsFailedAction = createAction(
  EntityDetailsConstant.UPDATE_COMMENT_FAILED,
  props<{ error: any }>()
);

export const loadCommentStartAction = createAction(
  EntityDetailsConstant.LOAD_COMMENT_START
);

export const loadCommentSucceededAction = createAction(
  EntityDetailsConstant.LOAD_COMMENT_SUCCEEDED,
  props<{ payload: EntityDetails }>()
);

export const loadCommentFailedAction = createAction(
  EntityDetailsConstant.LOAD_COMMENT_FAILED,
  props<{ error: any }>()
);


export const deleteCommentStartAction = createAction(
  EntityDetailsConstant.DELETE_COMMENT_START
);

export const deleteCommentFailedAction = createAction(
  EntityDetailsConstant.DELETE_COMMENT_FAILED,
  props<{ error: any }>()
);

export const addReplyStartAction = createAction(
  EntityDetailsConstant.ADD_REPLY_START
);

export const addReplyFailedAction = createAction(
  EntityDetailsConstant.ADD_REPLY_FAILED,
  props<{ error: any }>()
);

export const updateReplyStartAction = createAction(
  EntityDetailsConstant.UPDATE_REPLY_START
);

export const updateReplyFailedAction = createAction(
  EntityDetailsConstant.UPDATE_REPLY_FAILED,
  props<{ error: any }>()
);

export const deleteReplyStartAction = createAction(
  EntityDetailsConstant.DELETE_REPLY_START
);

export const deleteReplyFailedAction = createAction(
  EntityDetailsConstant.DELETE_REPLY_FAILED,
  props<{ error: any }>()
);

export const loadHistoryStartAction = createAction(
  EntityDetailsConstant.LOAD_HISTORY_START
);

export const loadHistorySucceededAction = createAction(
  EntityDetailsConstant.LOAD_HISTORY_SUCCEEDED,
  props<{ payload: EntityDetails }>()
);

export const loadHistoryFailedAction = createAction(
  EntityDetailsConstant.LOAD_HISTORY_FAILED,
  props<{ error: any }>()
);

export const loadAttachmentStartAction = createAction(
  EntityDetailsConstant.LOAD_ATTACHMENT_START
);

export const loadAttachmentSucceededAction = createAction(
  EntityDetailsConstant.LOAD_ATTACHMENT_SUCCEEDED,
  props<{ payload: EntityDetails }>()
);

export const loadAttachmentFailedAction = createAction(
  EntityDetailsConstant.LOAD_ATTACHMENT_FAILED,
  props<{ error: any }>()
);

export const uploadAttachmentStartAction = createAction(
  EntityDetailsConstant.UPLOAD_ATTACHMENT_START
);

export const uploadAttachmentSucceededAction = createAction(
  EntityDetailsConstant.UPLOAD_ATTACHMENT_SUCCEEDED,
  props<{ payload: EntityDetails }>()
);

export const uploadAttachmentFailedAction = createAction(
  EntityDetailsConstant.UPLOAD_ATTACHMENT_FAILED,
  props<{ error: any }>()
);

export const deleteAttachmentStartAction = createAction(
  EntityDetailsConstant.DELETE_ATTACHMENT_START
);

export const deleteAttachmentSucceededAction = createAction(
  EntityDetailsConstant.DELETE_ATTACHMENT_SUCCEEDED,
  props<{ payload: EntityDetails }>()
);

export const deleteAttachmentFailedAction = createAction(
  EntityDetailsConstant.DELETE_ATTACHMENT_FAILED,
  props<{ error: any }>()
);

export const updateAttachmentStartAction = createAction(
  EntityDetailsConstant.UPDATE_ATTACHMENT_START
);

export const updateAttachmentSucceededAction = createAction(
  EntityDetailsConstant.UPDATE_ATTACHMENT_SUCCEEDED,
  props<{ payload: EntityDetails }>()
);

export const updateAttachmentFailedAction = createAction(
  EntityDetailsConstant.UPDATE_ATTACHMENT_FAILED,
  props<{ error: any }>()
);

export const removeAllSucceededAction = createAction(
  EntityDetailsConstant.REMOVE_ALL_SUCCEEDED
);

export const updateTokenAction = createAction(
  EntityDetailsConstant.UPDATE_TOKEN,
  props<{ payload: EntityDetails }>()
);

export const loadMetaStartAction = createAction(EntityDetailsConstant.LOAD_META_START);

export const loadMetaSucceededAction = createAction(EntityDetailsConstant.LOAD_META_SUCCEEDED, props<{
  payload: EntityDetails
}>());

export const loadMetaFailedAction = createAction(EntityDetailsConstant.LOAD_META_FAILED, props<{ error: Error }>());


@Injectable({
  providedIn: 'root'
})
export class EntityDetailsAction extends AbstractDetailsAction {

  constructor(
    translateService: TranslateService,
    toastaService: ToastaService,
    store: Store
  ) {
    super(translateService, toastaService, store);
  }

  public loadDetailsStart(): void {
    this._dispatchType(loadDetailsStartAction());
  }

  public loadNexiaObjectDetailsStart(): void {
    this._dispatchType(loadNexiaObjectDetailsStartAction());
  }

  public loadCurrentActivityDiffStart(): void {
    this._dispatchType(loadCurrentActivityDiffStartAction());
  }

  public loadDetailsSucceeded(entity: Entity, comments: CdxComment[], attachments: CdxAttachment[], activities: EsPage<CdxActivity>, docEsPage: EsPage<CdxDocument>): void {
    if (!entity && !comments && !attachments && !activities) {
      this.loadDetailsFailed('entity and comments cannot be null or undefined');
      return;
    }
    const details: EntityDetails = {
      details: entity,
      comments: comments,
      attachments: attachments,
      activities: activities,
      docEsPage: docEsPage,
      token: entity.token,
      currentActivityDiff: new ActivityDiff()

    };
    this._dispatchSuccess(loadDetailsSucceededAction({payload: details}));
  }

  public loadNexiaObjectDetailsSucceeded(entity: Entity): void {
    if (!entity) {
      this.loadDetailsFailed('entity cannot be null or undefined');
      return;
    }
    const payload = new EntityDetails();
    payload.details = entity;
    this._dispatchSuccess(loadNexiaObjectDetailsSucceededAction({payload}));
  }

  public loadCurrentActivityDiffSucceeded(currentActivityDiff: ActivityDiff): void {
    const payload = new EntityDetails();
    payload.currentActivityDiff = currentActivityDiff;
    this._dispatchSuccess(loadCurrentActivityDiffSucceededAction({payload}));
  }

  public loadDetailsFailed(error: any): void {
    this._dispatchFail(loadDetailsFailedAction(error));
  }

  public loadNexiaObjectDetailsFailed(error: any): void {
    this._dispatchFail(loadNexiaObjectDetailsFailedAction({error}));
  }

  public loadCurrentActivityDiffFailed(error: any): void {
    this._dispatchFail(loadCurrentActivityDiffFailedAction({error}));
  }

  public addCommentsStart(): void {
    this._dispatchType(addCommentStartAction());
  }

  public addCommentsFailed(error: any): void {
    this._dispatchFail(addCommentFailedAction({error}));
  }

  public updateCommentsStart(): void {
    this._dispatchType(updateCommentStartAction());
  }

  public updateCommentsFailed(error: any): void {
    this._dispatchFail(updateCommentsFailedAction({error}));
  }

  public loadCommentStart(): void {
    this._dispatchType(loadCommentStartAction());
  }

  public loadCommentSucceeded(comment: CdxComment): void {
    if (!comment) {
      this.loadCommentFailed('Comment cannot be null or undefined');
      return;
    }
    const payload = new EntityDetails();
    payload.comments = [comment];
    this._dispatchSuccess(loadCommentSucceededAction({payload}));
  }

  public loadCommentFailed(error: any): void {
    this._dispatchFail(loadCommentFailedAction({error}));
  }

  public deleteCommentStart(): void {
    this._dispatchType(deleteCommentStartAction());
  }

  public deleteCommentFailed(error: any): void {
    this._dispatchFail(deleteCommentFailedAction({error}));
  }

  public addReplyStart(): void {
    this._dispatchType(addReplyStartAction());
  }

  public addReplyFailed(error: any): void {
    this._dispatchFail(addReplyFailedAction({error}));
  }

  public updateReplyStart(): void {
    this._dispatchType(updateReplyStartAction());
  }

  public updateReplyFailed(error: any): void {
    this._dispatchFail(updateReplyFailedAction({error}));
  }

  public deleteReplyStart(): void {
    this._dispatchType(deleteReplyStartAction());
  }

  public deleteReplyFailed(error: any): void {
    this._dispatchFail(deleteReplyFailedAction({error}));
  }

  public loadHistoryStart(): void {
    this._dispatchType(loadHistoryStartAction());
  }

  public loadHistorySucceeded(activities: EsPage<CdxActivity>): void {
    if (!activities) {
      this.loadHistoryFailed('History cannot be null or undefined');
      return;
    }
    const payload = new EntityDetails();
    payload.activities = activities;
    this._dispatchSuccess(loadHistorySucceededAction({payload}));
  }

  public loadHistoryFailed(error: any): void {
    this._dispatchFail(loadHistoryFailedAction({error}));
  }

  public loadAttachmentStart(): void {
    this._dispatchType(loadAttachmentStartAction());
  }

  public loadAttachmentSucceeded(attachment: CdxAttachment, tempAttachmentId: string = null): void {
    if (!attachment) {
      this.loadAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new EntityDetails();
    payload.attachments = [attachment];
    if (tempAttachmentId) {
      const attachmentToBeChanged: CdxAttachment = new CdxAttachment();
      attachmentToBeChanged.cdx_id = tempAttachmentId;
      payload.attachments.push(attachmentToBeChanged);
    }
    this._dispatchSuccess(loadAttachmentSucceededAction({payload}));
  }

  public loadAttachmentFailed(error: any): void {
    this._dispatchFail(loadAttachmentFailedAction({error}));
  }

  public uploadAttachmentStart(): void {
    this._dispatchType(uploadAttachmentStartAction());
  }

  public uploadAttachmentSucceeded(attachment: CdxAttachment): void {
    if (!attachment) {
      this.uploadAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new EntityDetails();
    payload.attachments = [attachment];
    this._dispatchSuccess(uploadAttachmentSucceededAction({payload}));
  }

  public uploadAttachmentFailed(error: any): void {
    this._dispatchFail(uploadAttachmentFailedAction({error}), false);
  }

  public deleteAttachmentStart(): void {
    this._dispatchType(deleteAttachmentStartAction());
  }

  public deleteAttachmentSucceeded(attachment: CdxAttachment): void {
    if (!attachment) {
      this.deleteAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new EntityDetails();
    payload.attachments = [attachment];
    this._dispatchSuccess(deleteAttachmentSucceededAction({payload}));
  }

  public deleteAttachmentFailed(error: any): void {
    this._dispatchFail(deleteAttachmentFailedAction({error}), false);
  }

  public updateAttachmentStart(): void {
    this._dispatchType(updateAttachmentStartAction());
  }

  public updateAttachmentSucceeded(attachment: CdxAttachment): void {
    if (!attachment) {
      this.updateAttachmentFailed('Attachment cannot be null or undefined');
      return;
    }
    const payload = new EntityDetails();
    payload.attachments = [attachment];
    this._dispatchSuccess(updateAttachmentSucceededAction({payload}));
  }

  public updateAttachmentFailed(error: any): void {
    this._dispatchFail(updateAttachmentFailedAction({error}), false);
  }

  public removeAll(): void {
    this._dispatchType(removeAllSucceededAction());
  }

  public updateToken(headers: HttpHeaders): void {
    const token = headers.get('Object-Authorization');
    if (Utils.notNullAndNotUndefined(token)) {
      const payload = new EntityDetails();
      payload.token = token;
      this._dispatchSuccess(updateTokenAction({payload}));
    }
  }



  public loadMetaStart(): void {
    this._dispatchType(loadMetaStartAction());
  }

  public loadMetaSucceeded(meta: Metadata): void {
    const payload = new EntityDetails();
    payload.details = new Entity();
    payload.details.metadata = meta;
    this._dispatchSuccess(loadMetaSucceededAction({payload}));
  }

  public loadMetaFailed(error: any): void {
    this._dispatchFail(loadMetaFailedAction({error}));
  }
}
